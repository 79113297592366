/* Semi-center div */
.semi-center {
    padding-top: 12rem;
    padding-bottom: 0.5rem;
}

/* Apply custom suffix text to bootstrap input form */
.suffix, .suffix-longer {
    margin-top: 5px;
    margin-left: 40px;
    position: absolute;
    font-size: 1.1rem;
    user-select: none;
    -webkit-user-select: none;    
    -moz-user-select: none;
    -ms-user-select: none;
    pointer-events: none;
}
.suffix-longer {
    margin-left: 48px;
}

.container {
    padding-left: 1.1rem;
    padding-right: 1.1rem;
}

.card {
    margin-top: 0.2rem;
    padding-bottom: 1rem;
    box-shadow: 0px 1px 1px rgb(0 0 0 / 0.1);
}

/* Different padding types */

.padding-top-1 {
    padding-top: 1rem;
}

.padding-top-2 {
    padding-top: 2rem;
}

.padding-top-3 {
    padding-top: 3rem;
}

.padding-top-4 {
    padding-top: 4rem;
}

.padding-top-9 {
    padding-top: 9rem;
    padding-bottom: 0.5rem;
}

.padding-img {
    padding-top: 3px;
}

.padding-img-4 {
    padding-top: 4px;
}

.padding-bottom-1 {
    padding-bottom: 1rem;
}

.padding-bottom-2 {
    padding-bottom: 2rem;
}

/* Positioning of info-button image */
.info-img {
    margin-bottom: 3px;
    margin-left: 5px;
}

.custom-select:hover {
    cursor: pointer;
}

#img-btn {
    background-color: #00000000;
    border: none;
}

label {
    white-space: nowrap;
}

.btn-bmv-longer {
    width: 150px;
}