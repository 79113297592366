/* Font imports */
@font-face {
    font-family: 'Avenir';
    src: local('Avenir'), url(./assets/fonts/avenir_regular.otf) format('truetype');
}
@font-face {
    font-family: 'Avenir Semibold';
    src: local('Avenir Semibold'), url(./assets/fonts/avenir_semibold.otf) format('truetype');
}
@font-face {
    font-family: 'Avenir Bold';
    src: local('Avenir Bold'), url(./assets/fonts/avenir_bold.otf) format('truetype');
}

/* Apply default background and font to app body */
body {
    font-family: "Avenir";
    background-color: #FAFAFD;
}

.card {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem !important;
    border-radius: 0.6rem;
    backdrop-filter: blur(14px);
    background-color: rgba(255, 255, 255);
    box-shadow: 0px 5px 15px rgb(0 0 0 / 0.3) !important;
}

input, select {
    border-radius: 0.4rem !important;
}

@media (min-width: 576px) {
    .card {
        padding-left: 2rem;
        padding-right: 2rem;
        padding-top: 1.5rem;
        padding-bottom: 2rem !important;
        border-radius: 0.6rem;
    }
}

.btn-primary {
    margin-left: 1rem;
    width: 110px;
    box-shadow: 0px 5px 15px rgb(0 0 0 / 0.3) !important;
    border-color: rgba(255, 255, 255, 0) !important;
    border: 1px solid !important;

}

.dropdown-toggle:hover {
    color: #5e9cfa !important;

}

.button-outlined {
    width: 110px;
    background-color: rgba(255, 255, 255, 0.1) !important;
    border-color: rgba(255, 255, 255, 1) !important;
    border: 1px solid !important;
    color: rgba(255, 255, 255, 1) !important;
    box-shadow: 0px 5px 15px rgb(0 0 0 / 0.3) !important;
}