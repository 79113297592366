/* Blurred background image */
.background-img {
    background: linear-gradient(to top, rgba(255, 255, 255, 0.0), rgba(255, 255, 255, 0.0)), url('../assets/images/background.png') no-repeat scroll center top transparent;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    height: 2000px;
}

body{
    -ms-overflow-style: none;
  scrollbar-width: none;
}

body::-webkit-scrollbar {
    display: none;
}