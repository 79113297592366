/* h3 text shadow */
h3{
  color: white;
  text-shadow: 4px 4px 15px rgb(0 0 0 / 0.8);
}

/* Center search bar */
.semi-center {
  padding-top: 12rem;
  padding-bottom: 0.5rem;
}

/* Styling of Google Autocomplete */
.pac-container {
  font-family: 'Avenir';
  line-height: 1.5;
  border: none;
  appearance: none;
  border-radius: 0.6rem !important;
  background-clip: padding-box;
  -webkit-appearance: none;
  transition: #0d6efd .15s ease-in-out, #0d6efd .15s ease-in-out !important;
  outline: #0d6efd !important;
}
.pac-container:after {
  content: none !important;
}
.pac-target-input {
  width: 100% !important;
  padding: .75rem .75rem;
  font-size: 1rem;
  color: #212529;
  line-height: 1.5;
  border-radius: 0.6rem !important;
  border: none;
  appearance: none;
  background-clip: padding-box;
  -webkit-appearance: none;
  transition: #0d6efd .15s ease-in-out, #0d6efd .15s ease-in-out !important;
  outline: #0d6efd !important;
  box-shadow: 0px 5px 15px rgb(0 0 0 / 0.45) !important;
}
.pac-item {
  font-size: 0.9rem;
}
.pac-item-query {
  font-size: 0.9rem;
  color: #212529;
}
.pac-matched {
  font-weight: 600;
}
.bottom {
  position: absolute;
  bottom: 2rem;
}