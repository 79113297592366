.card {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem !important;
    border-radius: 0.6rem;
    backdrop-filter: blur(14px);
    background-color: rgba(255, 255, 255);
    box-shadow: 0px 5px 15px rgb(0 0 0 / 0.3) !important;
}

/* apply prefix to input fields */
.prefix {
    margin-top: 6px;
    margin-left: 10px;
    position: absolute;
    font-size: 1.1rem;
    user-select: none;
    -webkit-user-select: none;    
    -moz-user-select: none;
    -ms-user-select: none;
    pointer-events: none;
}

.prefix-padding {
    padding-left: 22px;
}

.suffix-very-long {
    margin-left: 65px;
}

.margin-below {
    margin-bottom: 2rem;
}

.no-margin {
    margin-left: 0;
    width: 200px;
}

.min-width {
    min-width: 120px;
}

.btn-delete {
    margin-top: 0.5rem;
    margin-left: 0;
    width: 130px;
    background-color: #dc3545;
}