.card {
   /* margin-right: 0.8rem;
    margin-left: 0.8rem;
    margin-bottom: 0rem;
    padding-bottom: 0.5rem;
    */
}

#card-margin {
    margin-right: 0.8rem;
    margin-left: 0.8rem;
    margin-bottom: 0rem;
    padding-bottom: 0.5rem;
}

.row>* {
    flex-shrink: 1 !important;
}

.card-columns {
    column-gap: 0.25rem;
}

.button-export {
    width: 130px;
}

.text-white {
    color: white;
}

.btn-longer {
    width: 160px;
}

.color-blue {
    color: rgba(0, 0, 0, 0.9);
    white-space: normal;
}